import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { transitions } from 'polished';
import { toast } from 'react-toastify';

import Header from '../../partials/header/Header';
import { CommonAd } from './pages/CommonAd';
import { CustomRelease } from './pages/CustomRelease';
import { CustomPreRelease } from './pages/CustomPreRelease';

import { COLOR_2 } from '../../styled/variables/variables';
import { mqDesktop } from '../../styled/helpers/helpers';

import listingService from '../../services/listing/listing.service';
import listingServiceV2 from '../../services/listing/listing-v2.service';
import userService from '../../services/user/user.service'
import queryStringUtil from '../../utils/query-string/query-string.util';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';
import { useAuth0 } from '@auth0/auth0-react';

const customId = "custom-id-yes";

const SearchDetail = ({ history }) => {
  const { loginWithRedirect } = useAuth0();

  const [state, setState] = useState({
    listingId: null,
    slug: history.location.pathname.replace('/anuncio/', '') ?? null,
    inDashboard: queryStringUtil.parse(history).inDashboard,
    realEstate: null,
    adsRealEstate: null,
    adsOthersRealEstate: null,
  });

  useEffect(() => {
    getRealEstate();
  }, []);

  const getRealEstate = async () => {
    const user = userService.retrieve();
    let result;

    if (!state.inDashboard) {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        result = await listingServiceV2.getBySlug({
          slug: state.slug,
          token: user && user.token ? user.token : null,
        });
      } catch (error) {
        // toast.error('Erro buscando imóvel.', {
        //   autoClose: false,
        //   toastId: customId,
        // });

        return window.open('/', '_self');
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        result = await listingService.getBySlugInDashboard({
          slug: state.slug,
          token: user.token,
          pictureUrl: user.profile.image,
        });
      } catch (error) {
        toast.error('Você só pode acessar a dashboard de imóveis dos quais é proprietário.', {
          autoClose: false,
          toastId: customId,
        });

        if (user) {
          return window.open('/', '_self');
        } else {
          const pathRedirect = `${window.location.pathname}${window.location.search}`;
          localStorage.setItem('@Koort:PathRedirect', pathRedirect);
          
          loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
        }
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    setState(prev => ({
      ...prev,
      listingId: result ? result.listingId : null,
      realEstate: result,
    }));
  };

  if (!state.realEstate) return <Wrapper />;

  const isCustomRelease = state.realEstate.listing === "Lançamento" && state.realEstate.publication === "Super Destaque";
  const isCustomPreRelease = state.realEstate.listing === "Pré-lançamento" && state.realEstate.publication === "Super Destaque";

  if (isCustomRelease) return <CustomRelease realEstate={state.realEstate} adsRealEstate={state.adsRealEstate} adsOthersRealEstate={state.adsOthersRealEstate} history={history} />;

  if (isCustomPreRelease) return <CustomPreRelease realEstate={state.realEstate} adsRealEstate={state.adsRealEstate} adsOthersRealEstate={state.adsOthersRealEstate} history={history} />;

  return (
    <>
      <Header disableScrollEvent />
      <CommonAd history={history} />
    </>
  );
}

SearchDetail.propTypes = {
  history: propTypes.object,
};

SearchDetail.defaultProps = {};

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${COLOR_2};
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  ${transitions(['top', 'opacity', 'right'], 'ease .6s')};

  ${mqDesktop`
    padding-top: 80px;

    ${transitions([
  'top',
  'opacity',
  'right',
], 'ease .9s')};

    top: 80px;
    opacity: 1;
  ` };
`;

export default SearchDetail;
