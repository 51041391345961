import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { ContactForm } from '../../contact-form';
import { Features } from '../../features';
import AdCardInteractive from '../../../../components/ad-card-interactive-legacy';
import MainInfo from '../../main-info/MainInfo';
import Presentation from '../../presentation/Presentation';
import Prices from '../../prices/Prices';
import { TitleAndDescription } from '../../title-and-description';
import { DevelopmentInformation } from '../../development-information';
import { CTAWidget } from '../components/cta-widget';
import Footer from '../../../../partials/footer/Footer';

import listingService from '../../../../services/listing/listing.service';
import listingServiceV2 from '../../../../services/listing/listing-v2.service';
import userService from '../../../../services/user/user.service';
import realEstateService from '../../../../services/real-estate/real-estate.service';
import userFavoriteService from '../../../../services/user/favorite/user.favorite.service';

import eventsConstants from '../../../../constants/events';
import { propertyTypesForAdPage } from '../../../../constants/property-types';

import queryStringUtil from '../../../../utils/query-string/query-string.util';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';

import '../../search-detail.css';

import {
  Wrapper,
  ADDescriptionTextSection,
  ADRealEstateTextSection,
  AdCardInteractiveWrapper,
  AdReportContainer,
  ContentWrapper,
  FirstSectionDivider,
  HideOnDesktop,
  IncorporationInfoContainer,
  LeftSideDesktopWrapper,
  PriceAndContactStickyContainer,
  RightSideDesktopWrapper,
  SecondSectionDivider
} from './styles';

const customId = "custom-id-yes";

const USER_TYPES = {
  3: "CORRETOR(A)",
  4: "IMOBILIÁRIA",
  5: "INCORPORADORA",
};

export const CommonAd = ({ history }) => {
  const [state, setState] = useState({
    listingId: null,
    slug: history.location.pathname.replace('/anuncio/', '') ?? null,
    inDashboard: queryStringUtil.parse(history).inDashboard,
    realEstate: null,
    adsRealEstate: null,
    adsOthersRealEstate: null,
  });

  const [userStored, setUserStored] = useState();
  const [favorites, setFavorites] = useState();

  useEffect(() => {
    getRealEstate();
    getFavorites();

    const user = userService.retrieve();
    setUserStored(user);
  }, []);

  useEffect(() => {
    if (state.realEstate) {
      if (!state.inDashboard) {
        informPrint();
      }

      getRealEstateRecommendations();
    }
  }, [state.realEstate]);

  const images = [];

  const informPrint = async () => {
    try {
      await realEstateService.informView({
        id: state.listingId,
      });
    } catch (error) {
      console.error(error);
      // Do nothing.
    }
  };

  const getFavorites = async () => {
    const user = userService.retrieve();

    if (user) {
      setUserStored(user);
      const favoritesAds = await userFavoriteService.get({
        token: user.token,
      });

      setFavorites(favoritesAds)
    }
  }

  function searchFavoriteAdOnFavorites(adId) {
    if (favorites && favorites.includes(adId)) return true;

    return false
  }

  const getRealEstate = async () => {
    const user = userService.retrieve();
    let result;

    if (!state.inDashboard) {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        result = await listingServiceV2.getBySlug({
          slug: state.slug,
          token: user && user.token ? user.token : null,
        });
      } catch (error) {
        toast.error('Erro buscando imóvel.', {
          autoClose: false,
          toastId: customId,
        });
        return;
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        result = await listingService.getBySlugInDashboard({
          slug: state.slug,
          token: user.token,
          pictureUrl: user.profile.image,
        });
      } catch (error) {
        toast.error('Erro buscando imóvel.', {
          autoClose: false,
          toastId: customId,
        });
        return;
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    setState(prev => ({
      ...prev,
      listingId: result ? result.listingId : null,
      realEstate: result,
    }));
  };

  const getRealEstateRecommendations = async () => {
    const user = userService.retrieve();

    try {
      const {
        status,
        listingsInTheSameNeighborhood,
        othersListings,
      } = await listingServiceV2.getRecommendedAds({
        id: state.listingId,
        token: (user ? user.token : null),
      });

      if (status !== 200) {
        throw new Error("Não foi possível buscar as recomendações");
      }

      setState(prev => ({
        ...prev,
        adsOthersRealEstate: listingsInTheSameNeighborhood,
        adsRealEstate: othersListings,
      }));
    } catch (err) {
      console.error(err);
    }
  }

  if (!state.realEstate) return <Wrapper />;

  const descriptionWithBreakLine = state.realEstate.description.split('\n');

  return (
    <>
      <Helmet>
        <title>{state.realEstate.seo.headTitle}</title>
        <meta name="title" content={state.realEstate.seo.headTitle} />
        <meta name="description" content={state.realEstate.seo.headDescription} />

        <meta property="og:title" content={state.realEstate.seo.headTitle} />
        <meta property="og:description" content={state.realEstate.seo.headDescription} />
        <meta property="og:url" content={window.location.href} />

        <meta property="twitter:title" content={state.realEstate.seo.headTitle} />
        <meta property="twitter:description" content={state.realEstate.seo.headDescription} />
        <meta property="twitter:url" content={window.location.href} />
      </Helmet>

      <Wrapper>
        <Presentation {...state.realEstate} adInfo={state.realEstate} />
        <ContentWrapper>
          <LeftSideDesktopWrapper>
            <MainInfo
              {...state.realEstate}
              {...state.realEstate.address}
              contact={state.realEstate.contact}
            />
            <FirstSectionDivider />
            <Features
              bedrooms={state.realEstate.bedrooms}
              area={state.realEstate.area}
              parkingSpaces={state.realEstate.parkingSpaces}
              bathrooms={state.realEstate.bathrooms}
              petAcceptance={state.realEstate.petAcceptance}
              isRelease={state.realEstate.listing === 'Lançamento'}
            />
            <SecondSectionDivider />
            <HideOnDesktop>
              <Prices
                {...state.realEstate}
                {...state.realEstate.price}
                transaction={state.realEstate.transaction}
                favorite={searchFavoriteAdOnFavorites(state.listingId)}
                showMobile
              />
            </HideOnDesktop>

            {state.realEstate && state.realEstate.listing === 'Lançamento' &&
              state.realEstate.development.name && state.realEstate.development.constructionStatus && (
                <DevelopmentInformation
                  development={state.realEstate.development}
                />
              )}

            <TitleAndDescription
              title='Sobre o imóvel:'
              content={descriptionWithBreakLine}
            />

            {state.realEstate && state.realEstate.features.length > 0 && (
              <>
                <ADDescriptionTextSection
                  title='Características gerais:'
                />

                {state.realEstate.features.find(item => item.group === 'PROPERTY') && (
                  <ADRealEstateTextSection
                    title='Imóvel:'
                    content={(() => {
                      let content = '';
                      state.realEstate.features.forEach(f => {
                        if (f.group === 'PROPERTY') {
                          content += `${f.label} / `;
                        }
                      });
                      content = content.substring(0, content.length - 2);
                      return content;
                    })()}
                  />
                )}

                {state.realEstate.features.find(item => item.group === 'CONDO') && (
                  <ADRealEstateTextSection
                    title='Condomínio:'
                    content={(() => {
                      let content = '';
                      state.realEstate.features.forEach(f => {
                        if (f.group === 'CONDO') {
                          content += `${f.label} / `;
                        }
                      });
                      content = content.substring(0, content.length - 2);
                      return content;
                    })()}
                  />
                )}

                {state.realEstate.features.find(item => item.group === 'SURROUNDINGS') && (
                  <ADRealEstateTextSection
                    title='Proximidades:'
                    content={(() => {
                      let content = '';
                      state.realEstate.features.forEach(f => {
                        if (f.group === 'SURROUNDINGS') {
                          content += `${f.label} / `;
                        }
                      });
                      content = content.substring(0, content.length - 2);
                      return content;
                    })()}
                  />
                )}
              </>
            )}

            {state.realEstate && state.realEstate.publisher.type === USER_TYPES[5] && (
              <IncorporationInfoContainer>
                <h2>Incorporação <span>| {state.realEstate.incorporation.name}</span></h2>

                <div>
                  <img src={state.realEstate.incorporation.image} />
                  <p>{state.realEstate.incorporation.about}</p>
                </div>
              </IncorporationInfoContainer>
            )}

            <HideOnDesktop>
              <ContactForm
                isRelease={state.realEstate.listing === 'Lançamento'}
                listingId={state.listingId}
                publisher={state.realEstate.publisher}
                isIncorporation={state.realEstate.publisher.type === USER_TYPES[5]}
                incorporationInfo={state.realEstate.incorporation || null}
              />
            </HideOnDesktop>
          </LeftSideDesktopWrapper>

          <RightSideDesktopWrapper>
            <PriceAndContactStickyContainer>
              <Prices
                {...state.realEstate}
                {...state.realEstate.price}
                transaction={state.realEstate.transaction}
                favorite={searchFavoriteAdOnFavorites(state.listingId)}
                history={history}
              />

              <div className={'contact-form'}>
                <ContactForm
                  isRelease={state.realEstate.listing === 'Lançamento'}
                  listingId={state.listingId}
                  publisher={state.realEstate.publisher}
                  contact={state.realEstate.contact ? state.realEstate.contact.phone.number : ''}
                  isListing={true}
                  isIncorporation={state.realEstate.publisher.type === USER_TYPES[5]}
                  incorporationInfo={state.realEstate.incorporation || null}
                />
              </div>
            </PriceAndContactStickyContainer>
          </RightSideDesktopWrapper>
        </ContentWrapper>

        {state.adsOthersRealEstate && state.adsOthersRealEstate.length > 0 &&
          <>
            <div className={'container-title-recommendation'}>
              <h2>
                {propertyTypesForAdPage[state.realEstate.property][0]} {' '}
                <strong>{propertyTypesForAdPage[state.realEstate.property][1]}</strong> {' '}
                no bairro <strong>{state.realEstate.address.neighborhood}</strong>:
              </h2>
            </div>
            <div className={'container-recommendation'}>
              {state.adsOthersRealEstate && state.adsOthersRealEstate.slice(0, 8).map((adRealEstate) => (
                <>
                  <div className={'card-recommendation'}>
                    <AdCardInteractiveWrapper>
                      <AdCardInteractive
                        key={adRealEstate.id}
                        user={userStored}
                        {...adRealEstate}
                        {...adRealEstate.prices}
                        {...adRealEstate.address}
                        favorite={searchFavoriteAdOnFavorites(adRealEstate.id)}
                      />
                    </AdCardInteractiveWrapper>
                  </div>
                </>
              ))}
            </div>
          </>
        }

        {state.adsRealEstate && state.adsRealEstate.length > 0 &&
          <>
            <div className={'container-title-recommendation'}>
              <h2>Você também pode se interessar por:</h2>
            </div>
            <div className={'container-recommendation'}>

              {state.adsRealEstate && state.adsRealEstate.slice(0, 8).map((adRealEstate) => (
                <>
                  <div className={'card-recommendation'}>
                    <AdCardInteractiveWrapper>
                      <AdCardInteractive
                        key={adRealEstate.id}
                        user={userStored}
                        {...adRealEstate}
                        {...adRealEstate.prices}
                        {...adRealEstate.address}
                        favorite={searchFavoriteAdOnFavorites(adRealEstate.id)}
                      />
                    </AdCardInteractiveWrapper>
                  </div>
                </>
              ))}
            </div>
          </>
        }

        <AdReportContainer>
          <p>
            Você notou alguma inconsistência neste anúncio? Se assim for, você pode nos avisar na {' '}
            <Link to={`/ad-report?adId=${state.listingId}`}>
             página de denúncia
            </Link>
            .
          </p>
        </AdReportContainer>
      </Wrapper>

      <CTAWidget onClick={() => document.getElementById('call-to-action-button').click()} />
      <Footer />
    </>
  );
}

CommonAd.propTypes = {
  history: propTypes.object,
};

CommonAd.defaultProps = {};
