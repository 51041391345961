import React, { PureComponent } from 'react';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import Pagination from '../../../../../../src/components/pagination';
import RealtorCard from '../../../../../../src/pages/realtors-search/realtor-card/RealtorCard';
import { Select } from '../../../../home/cover/search-form/components/select';

import listingV2Service from '../../../../../services/listing/listing-v2.service';

import pathsConstants from '../../../../../constants/paths';
import queryStringUtil from '../../../../../utils/query-string/query-string.util';

import {
  Wrapper,
  SectionTitle,
  SearchBarWrapper,
  RealtorsList,
  ItemFormWrapper,
  SelectWrapper,
  Button,
 } from './styles';

/* global window */

class SearchInput extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      realtors: [],
      findRealtorsButtonDisabled: true,

      citiesOptions: [],
      districtsOptions: [],
      professionalTypeOptions: [
        { label: 'Corretores(as)', value: '3' },
        { label: 'Imobiliárias', value: '4' },
        { label: 'Incorporadoras', value: '5' },
      ],
      selectedCityOption: null,
      selectedDistrictOption: null,
      selectedProfessionalTypeOption: null,
    }

    this.inputRef = React.createRef();
  }

  search = async () => {
    const { history } = this.props;

    this.props.handlePressFindRealtors();

    this.setState(prevState => ({
      ...prevState,
      findRealtorsButtonDisabled: true,
    }));

    history.push({
      pathname: pathsConstants.REALTORS_SEARCH,
      search: queryStringUtil.stringify(history, {
        city: (this.state.selectedCityOption ? this.state.selectedCityOption.value : ''),
        district: (this.state.selectedDistrictOption ? this.state.selectedDistrictOption.value : ''),
        professionalType: (this.state.selectedProfessionalTypeOption ? this.state.selectedProfessionalTypeOption.value : ''),
      }),
    });
  }

  handleWrapperClick = () => {

  };

  getCities = async () => {
    try {
      const { status, data } = await listingV2Service.getCities({});

      if (status !== 200) {
        throw new Error('Não foi possível buscar as cidades!');
      }

      const citiesOptions = data.map(item => {
        return {
          label: item.state,
          options: item.cities.map(city => {
            return {
              value: city,
              label: city,
              propertyTypes: item.propertyTypes,
              bedrooms: item.bedrooms,
              uf: item.uf,
            }
          })
        }
      });

      this.setState(prevState => ({
        ...prevState,
        citiesOptions,
      }));
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  getDistricts = async ({ city }) => {
    try {
      const { status, data } = await listingV2Service.getDistricts({ city });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os bairros!');
      }

      const districtsOptions = data.map(district => {
        return {
          label: district.neighborhood,
          value: district.neighborhood,
        }
      });

      this.setState(prevState => ({
        ...prevState,
        districtsOptions,
      }));
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  async componentDidMount() {
    await this.getCities();

    const { history } = this.props;
    const { city, district, professionalType } = queryStringUtil.parse(history);

    if (city) {
      this.setState(prevState => ({
        ...prevState,
        selectedCityOption: {
          label: city,
          value: city,
        }
      }));

      await this.getDistricts({ city });

      if (district) {
        this.setState(prevState => ({
          ...prevState,
          selectedDistrictOption: {
            label: district,
            value: district,
          }
        }));
      }
    }

    if (professionalType) {
      const types = {
        '3': 'Corretores(as)',
        '4': 'Imobiliárias',
        '5': 'Incorporadoras',
      }

      this.setState(prevState => ({
        ...prevState,
        selectedProfessionalTypeOption: {
          label: types[professionalType],
          value: professionalType,
        }
      }));
    }
  }

  handleChangeCity = async (option) => {
    try {
      const { status, data } = await listingV2Service.getDistricts({
        city: option.value,
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os bairros!');
      }

      const districtsOptions = data.map(district => {
        return {
          label: district.neighborhood,
          value: district.neighborhood,
        }
      });

      this.setState(prevState => ({
        ...prevState,
        districtsOptions,
        selectedCityOption: option,
        selectedDistrictOption: null,
        findRealtorsButtonDisabled: false,
      }));
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      })
    }
  }

  handleChangeDistrict = (option) => {
    this.setState(prevState => ({
      ...prevState,
      selectedDistrictOption: option,
      findRealtorsButtonDisabled: false,
    }));
  }

  handleChangeProfessionalType = (option) => {
    this.setState(prevState => ({
      ...prevState,
      selectedProfessionalTypeOption: option,
      findRealtorsButtonDisabled: false,
    }));
  }

  render() {
    const {
      realtors,
      pageCount,
      resultsCount,
      initialPage,
      text,
      onPageChange,
      citiesOptions,
      selectedCityOption,
      districtsOptions,
      selectedDistrictOption,
      professionalTypeOptions,
      selectedProfessionalTypeOption
    } = this.state;

    return (
      <Wrapper onClick={this.handleWrapperClick}>
        <SearchBarWrapper>
          <Row style={{ maxWidth: '700px', margin: '0px' }}>
            <Col xl={6} xs={12} style={{ padding: 0 }}>
              <ItemFormWrapper className="city">
                <SelectWrapper>
                  <label>Cidade</label>
                  <Select
                    name="city-select"
                    options={citiesOptions}
                    onChange={option => this.handleChangeCity(option)}
                    placeholder="Pesquise uma cidade"
                    value={selectedCityOption}
                  />
                </SelectWrapper>
              </ItemFormWrapper>
            </Col>
            <Col xl={6} xs={12} style={{ padding: 0 }}>
              <ItemFormWrapper className="district">
                <SelectWrapper>
                  <label>Bairro</label>
                  <Select
                    name="bairro-select"
                    onChange={option => this.handleChangeDistrict(option)}
                    placeholder="Pesquise um bairro"
                    noOptionsMessage={() => 'Escolha a cidade'}
                    options={districtsOptions}
                    value={selectedDistrictOption}
                  />
                </SelectWrapper>
              </ItemFormWrapper>
            </Col>
          </Row>
        </SearchBarWrapper>

        <Col xl={12} xs={12} style={{ padding: 0 }}>
          <ItemFormWrapper className="professionalType">
            <SelectWrapper>
              <label>Categoria</label>
              <Select
                name="professional-type-select"
                onChange={option => this.handleChangeProfessionalType(option)}
                placeholder="Selecione"
                options={professionalTypeOptions}
                value={selectedProfessionalTypeOption}
                isSearchable={false}
              />
            </SelectWrapper>
          </ItemFormWrapper>
        </Col>

        <Button
          onClick={this.search}
          disabled={this.state.findRealtorsButtonDisabled}
          isDisabled={this.state.findRealtorsButtonDisabled}
        >
          ENCONTRAR
        </Button>


        {realtors.length > 0 &&
          <>
            <Wrapper id="realtor-search-page-wrapper">
              <SectionTitle>
                Sua busca retornou {resultsCount} {resultsCount > 1 ? "corretores" : "corretor"}
              </SectionTitle>
              <RealtorsList>
                {realtors.map((realtor, i) => (
                  <li key={i}>
                    <RealtorCard {...realtor} />
                  </li>
                ))}
              </RealtorsList>
              {pageCount > 0 &&
                <Pagination initialPage={initialPage} onPageChange={onPageChange} pageCount={pageCount} />
              }
            </Wrapper>
          </>
        }
        {
          realtors.length === 0 && text && (
            <Wrapper id="realtor-search-page-wrapper">
              <SectionTitle>
                Sua busca não retornou nenhum resultado
              </SectionTitle>
              <p>Tente uma nova busca com outros termos.</p>
            </Wrapper>
          )
        }
      </Wrapper>
    );
  }
}

SearchInput.propTypes = {
  onChange: propTypes.func.isRequired,
  onPlaceholderClick: propTypes.func.isRequired,
  value: propTypes.string.isRequired,
};

export default SearchInput;
