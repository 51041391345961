import httpClient from "../../clients/http/http.client";
import Address from "../../models/address/address.model";
import Geolocation from "../../models/geolocation/geolocation.model";
import Image, { TYPES as IMAGE_TYPES } from "../../models/image/image.model";
import Price from "../../models/price/price.model";
import SurroundingPlace from "../../models/surrounding-place/surrounding-place.model";
import objectUtil from "../../utils/object/object.util";
import Koortimativa from "../../models/koortimativa/koortimativa.model";
import RealEstate from "../../models/real-estate/real-estate.model";
import Feature, { GROUP_TYPES } from "../../models/feature/feature.model";
import Publisher from "../../models/publisher/publisher.model";

const BASE_PATH = "/v1";
const { REACT_APP_SEARCH_API_URL } = process.env;

const USER_TYPES = {
  3: "CORRETOR(A)",
  4: "IMOBILIÁRIA",
  5: "INCORPORADORA",
};

const getNew = async ({ page = 1, pageSize = 10 }) => {
  const skip = (page - 1) * pageSize;
  const path = `${BASE_PATH}/listings`;
  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  const { items } = response.data;

  const result = items.map((i) => {
    const { ad, ad_m, ad_g, floor_plan, tour360 } = i.ad.images;

    const mapped = new RealEstate({
      ...i,
      ...i.ad,
      ...i.ad.development,
      ...i.ad.types,
      highlight: i.ad.types.publication === "Super Destaque",
      subtitle: i.ad.subTitle,
      listingId: i._id,
      area: i.ad.area[0],
      contact: i.ad.contact,
      parkingSpaces: i.ad.parkingSpaces[0],
      suites: i.ad.suites[0],
      petAcceptance: !!i.ad.features.condo.find((c) => c === "Aceita Pet"),
      bathrooms: i.ad.bathrooms[0],
      bedrooms: i.ad.bedrooms[0],
      address: new Address({
        ...i.ad.address,
        number: i.ad.address.streetNumber,
        geolocation: new Geolocation({
          ...i.ad.address.location,
          latitude: i.ad.address.location.coordinates[1],
          longitude: i.ad.address.location.coordinates[0],
        }),
      }),
      images: (ad || [])
        .map(
          (url) =>
            new Image({
              url,
              size: "p",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (ad_m || []).map(
            (url) =>
              new Image({
                url,
                size: "m",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (ad_g || []).map(
            (url) =>
              new Image({
                url,
                size: "g",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        ),
      price: new Price({
        ...i.ad.prices,
        main: i.ad.prices.main,
      }),
    });

    return mapped;
  });

  let startSlice, endSlice;
  if (result.length > skip + pageSize) {
    startSlice = skip;
    endSlice = skip + pageSize;
  } else {
    startSlice = 0;
    endSlice = pageSize;
  }

  return result.slice(startSlice, endSlice);
};

const getByIdInDashboard = async ({ listingId, token, pictureUrl }) => {
  const path = `/realestates/${listingId}?complete=true`;
  const response = await httpClient.get({ path, token });

  const { realEstate, ads, _id, userId } = response.data;
  const { near, condo, property } = realEstate.features;

  const result = new RealEstate({
    incorporation: response.data.incorporation ?? null,
    development: response.data.ads.development ?? null,
    listing: ads.types.listing,
    video: response.data.ads.video,
    description: ads.description,
    listingId: _id,
    ...realEstate,
    ...realEstate.types,
    area: realEstate.area,
    bathrooms: realEstate.bathrooms,
    petAcceptance: !!condo.find((c) => c === "Aceita Pet"),
    bedrooms: realEstate.bedrooms,
    parkingSpaces: realEstate.parkingSpaces,
    highlight: ads.types.publication === "Super Destaque",
    title: ads.title,
    subtitle: ads.subTitle,
    contact: ads.contact,
    suites: realEstate.suites,
    address: new Address({
      ...realEstate.address,
      number: realEstate.address.streetNumber,
      geolocation: new Geolocation({
        ...realEstate.address.location,
        latitude: realEstate.address.location.coordinates[1],
        longitude: realEstate.address.location.coordinates[0],
      }),
    }),
    price: new Price({
      ...ads.prices,
      main: ads.prices.main,
    }),
    features: (near || [])
      .map(
        (l) =>
          new Feature({
            label: l,
            group: GROUP_TYPES.SURROUNDINGS,
          })
      )
      .concat(
        (condo || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.CONDO,
            })
        )
      )
      .concat(
        (property || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.PROPERTY,
            })
        )
      ),
    images: (ads.images.ad || [])
      .map(
        (url) =>
          new Image({
            url: url.replace("{width}", "1024"),
            size: "g",
            type: IMAGE_TYPES.PICTURE,
          })
      )
      .concat(
        (ads.images.floor_plan || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.FLOOR_PLAN,
            })
        )
      )
      .concat(
        (ads.images.tour360 || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.PANORAMA,
            })
        )
      )
      .concat(
        (ads.images && ads.images.slides ? ads.images.slides : []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.SLIDES,
            })
        )
      ),
    koortimativa: new Koortimativa({
      ...ads.koortimativa,
      rangeStart: ads.koortimativa.range[0],
      rangeEnd: ads.koortimativa.range[1],
    }),
    publisher: new Publisher({
      ...userId,
      emails: [userId.email],
      pictureUrl: pictureUrl,
      id: userId._id,
    }),
  });

  return result;
};

const getBySlugInDashboard = async ({ slug, token, pictureUrl }) => {
  if (slug.includes("-id-")) {
    const itemsFromSlugSplited = slug.split("-");
    slug = itemsFromSlugSplited[itemsFromSlugSplited.length - 1];
  }

  const path = `/realestates/slug/${slug}?complete=true`;
  const response = await httpClient.get({ path, token });

  const { realEstate, ads, _id, userId } = response.data;
  const { near, condo, property } = realEstate.features;

  const result = new RealEstate({
    seo: response.data.ads.seo,
    externalId: response.data.externalId,
    incorporation: response.data.incorporation ?? null,
    development: response.data.ads.development ?? null,
    listing: ads.types.listing,
    video: response.data.ads.video,
    description: ads.description,
    featuresOriginFormat: realEstate.features,
    listingId: _id,
    ...realEstate,
    ...realEstate.types,
    transaction: ads.types.transaction,
    publication: ads.types.publication,
    launch: ads.launch,
    area: realEstate.area,
    bathrooms: realEstate.bathrooms,
    petAcceptance: !!condo.find((c) => c === "Aceita Pet"),
    bedrooms: realEstate.bedrooms,
    parkingSpaces: realEstate.parkingSpaces,
    highlight: ads.types.publication === "Super Destaque",
    title: ads.title,
    subtitle: ads.subTitle,
    contact: ads.contact,
    suites: realEstate.suites,
    address: new Address({
      ...realEstate.address,
      number: realEstate.address.streetNumber,
      geolocation: new Geolocation({
        ...realEstate.address.location,
        latitude: realEstate.address.location.coordinates[1],
        longitude: realEstate.address.location.coordinates[0],
      }),
    }),
    price: new Price({
      ...ads.prices,
      main: [ads.prices.main],
    }),
    features: (near || [])
      .map(
        (l) =>
          new Feature({
            label: l,
            group: GROUP_TYPES.SURROUNDINGS,
          })
      )
      .concat(
        (condo || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.CONDO,
            })
        )
      )
      .concat(
        (property || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.PROPERTY,
            })
        )
      ),
    images: (ads.images.ad || [])
      .map(
        (url) =>
          new Image({
            url: url.replace("{width}", "550"),
            size: "g",
            type: IMAGE_TYPES.PICTURE,
          })
      )
      .concat(
        (ads.images.floor_plan || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.FLOOR_PLAN,
            })
        )
      )
      .concat(
        (ads.images.tour360 || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.PANORAMA,
            })
        )
      )
      .concat(
        (ads.images && ads.images.slides ? ads.images.slides : []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.SLIDES,
            })
        )
      ),
    koortimativa: new Koortimativa({
      ...ads.koortimativa,
      rangeStart: ads.koortimativa.range[0],
      rangeEnd: ads.koortimativa.range[1],
    }),
    publisher: new Publisher({
      ...userId,
      emails: [userId.email],
      pictureUrl: pictureUrl,
      id: userId._id,
      type: USER_TYPES[userId.userType]
    }),
  });

  return result;
};

const getById = async ({ listingId }) => {
  // /dashboard/ads/dashboard/
  const path = `${BASE_PATH}/listings/${listingId}`;
  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });
  const { _id, ad, publisher } = response.data.source;

  // TODO: Remove it when data is consistent.
  // if(ad.images.ad instanceof Array) {
  //   ad.images = {
  //     ad: ad.images,
  //   };
  // }

  // TODO: Remove it when data is consistent.
  if (ad.features instanceof Array) {
    ad.features = {
      property: ad.features,
    };
  }
  const { ad: adImages, floor_plan, tour360 } = ad.images;
  const { near, condo, property } = ad.features;

  const result = new RealEstate({
    listingId: _id,
    ...ad,
    ...ad.types,
    area: ad.area,
    bathrooms: ad.bathrooms,
    petAcceptance: !!condo.find((c) => c === "Aceita Pet"),
    bedrooms: ad.bedrooms,
    parkingSpaces: ad.parkingSpaces,
    highlight: ad.types.publication === "Super Destaque",
    subtitle: ad.subTitle,
    suites: ad.suites,
    surroundingPlaces: (() => {
      const mapped = [];
      ad.near.forEach((n) => {
        n.points.forEach((p) => {
          const categoryId =
            n.typeId < 10
              ? `0${n.typeId}`
              : n.typeId
              ? n.typeId.toString()
              : ``;
          const sp = new SurroundingPlace({
            ...p,
            id: p.place_id,
            formattedAddress: p.address,
            subCategory: p.type,
            category: n.name, // .toLowerCase(),
            categoryId,
            geolocation: new Geolocation({
              latitude: p.lat,
              longitude: p.lng,
            }),
          });
          mapped.push(sp);
        });
      });
      return mapped;
    })(),
    address: new Address({
      ...ad.address,
      number: ad.address.streetNumber,
      geolocation: new Geolocation({
        ...ad.address.location,
        latitude: ad.address.location.coordinates[1],
        longitude: ad.address.location.coordinates[0],
      }),
    }),
    price: new Price({
      ...ad.prices,
      main: ad.prices.main,
    }),
    features: (near || [])
      .map(
        (l) =>
          new Feature({
            label: l,
            group: GROUP_TYPES.SURROUNDINGS,
          })
      )
      .concat(
        (condo || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.CONDO,
            })
        )
      )
      .concat(
        (property || []).map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.PROPERTY,
            })
        )
      ),
    images: (ad.images.ad || [])
      .map(
        (url) =>
          new Image({
            url: url.replace("{width}", "1024"),
            size: "g",
            type: IMAGE_TYPES.PICTURE,
          })
      )
      .concat(
        (floor_plan || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.FLOOR_PLAN,
            })
        )
      )
      .concat(
        (tour360 || []).map(
          (url) =>
            new Image({
              url,
              type: IMAGE_TYPES.PANORAMA,
            })
        )
      ),
    koortimativa: new Koortimativa({
      ...ad.koortimativa,
      rangeStart: ad.koortimativa.range[0],
      rangeEnd: ad.koortimativa.range[1],
    }),
    publisher: new Publisher({
      ...publisher,
      emails: [publisher.email],
      pictureUrl: publisher.profile.image,
      id: publisher._id,
    }),
  });

  return result;
};

const get = async ({
  type,
  bedrooms,
  bathrooms,
  suites,
  parkingSpaces,
  area,
  mainPrice,
  rect,
  transaction,
  use,
  typeOfGuarantee,
  features,
  ids,
  text,
  city,
  neighborhood,
  token,
}) => {
  const path = `${BASE_PATH}/listings`;
  const params = {};
  if (ids) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    params.ids = objectUtil.stringify(ids);
  } else {
    if (type !== undefined) {
      if (!Array.isArray(type)) {
        type = [type];
      }
      params.type = objectUtil.stringify(type);
    }
    if (bedrooms !== undefined) {
      if (!Array.isArray(bedrooms)) {
        bedrooms = [bedrooms];
      }
      params.bedrooms = objectUtil.stringify(bedrooms);
    }
    if (bathrooms !== undefined) {
      if (!Array.isArray(bathrooms)) {
        bathrooms = [bathrooms];
      }
      params.bathrooms = objectUtil.stringify(bathrooms);
    }
    if (suites !== undefined) {
      if (!Array.isArray(suites)) {
        suites = [suites];
      }
      params.suites = objectUtil.stringify(suites);
    }
    if (parkingSpaces !== undefined) {
      if (!Array.isArray(parkingSpaces)) {
        parkingSpaces = [parkingSpaces];
      }
      params.parkingSpaces = objectUtil.stringify(parkingSpaces);
    }
    if (area !== undefined) {
      if (!Array.isArray(area)) {
        area = [area];
      }
      params.area = objectUtil.stringify(area);
    }
    if (mainPrice !== undefined) {
      if (!Array.isArray(mainPrice)) {
        mainPrice = [mainPrice];
      }
      params.mainPrice = objectUtil.stringify(mainPrice);
    }
    if (rect !== undefined) {
      if (!Array.isArray(rect)) {
        rect = [rect];
      }
      params.rect = objectUtil.stringify(rect);
    }
    if (city) {
      params.city = city;
    }
    if (neighborhood) {
      params.neighborhood = neighborhood;
    }
    if (transaction !== undefined) {
      params.transaction = objectUtil.stringify(transaction);
    }
    if (use !== undefined) {
      if (!Array.isArray(use)) {
        use = [use];
      }
      params.use = objectUtil.stringify(use);
    }
    if (typeOfGuarantee !== undefined) {
      if (!Array.isArray(typeOfGuarantee)) {
        typeOfGuarantee = [typeOfGuarantee];
      }
      params.typeOfGuarantee = objectUtil.stringify(typeOfGuarantee);
    }
    if (features !== undefined) {
      if (!Array.isArray(features)) {
        features = [features];
      }
      params.features = objectUtil.stringify(features);
    }
    if (text !== undefined) params.text = text;
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    params,
    token: token,
  });

  const { items } = response.data;
  const result = items.reverse().map((i) => {
    // TODO: Remove it when data is consistent.
    // if(i.ad.images instanceof Array) {
    //   i.ad.images = {
    //     ad: i.ad.images,
    //     ad_small: i.ad.images,
    //   };
    // }

    const { ad, ad_m, ad_g, floor_plan, tour360 } = i.ad.images;
    // const { features } = i.ad;

    // let near = [ ], condo = [ ], property = [ ];
    // if(features) {
    //   near = features.near;
    //   condo = features.condo;
    //   property = features.property;
    // }
    const mapped = new RealEstate({
      ...i,
      ...i.ad,
      ...i.ad.development,
      ...i.ad.types,
      listingId: i._id,
      favorite: i.favorite ? i.favorite : false,
      highlight: i.ad.types.publication === "Super Destaque",
      subtitle: i.ad.subTitle,
      area: i.ad.area[0],
      parkingSpaces: i.ad.parkingSpaces[0],
      suites: i.ad.suites[0],
      bathrooms: i.ad.bathrooms[0],
      bedrooms: i.ad.bedrooms[0],
      // TODO: Remove ternary when backend data is stable.
      // petAcceptance: !!condo.find(c => c === 'Aceita Pet'),
      // features: near.map(l => new Feature({
      //   label: l,
      //   group: GROUP_TYPES.SURROUNDINGS,
      // })).concat(condo.map(l => new Feature({
      //   label: l,
      //   group: GROUP_TYPES.CONDO,
      // }))).concat(property.map(l => new Feature({
      //   label: l,
      //   group: GROUP_TYPES.PROPERTY,
      // }))),
      address: new Address({
        ...i.ad.address,
        number: i.ad.address.streetNumber,
        geolocation: new Geolocation({
          ...i.ad.address.location,
          latitude: i.ad.address.location.coordinates[1],
          longitude: i.ad.address.location.coordinates[0],
        }),
      }),
      city: i.ad.address.city,
      neighborhood: i.ad.address.neighborhood,
      images: (ad || [])
        .map(
          (url) =>
            new Image({
              url,
              size: "p",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (ad_m || []).map(
            (url) =>
              new Image({
                url,
                size: "m",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (ad_g || []).map(
            (url) =>
              new Image({
                url,
                size: "g",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url,
                size: "g",
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        ),
      price: new Price({
        ...i.ad.prices,
        main: i.ad.prices.main,
      }),
    });

    return mapped;
  });

  return result;
};

const informPrint = async ({ listingId }) => {
  const path = `/listings/increment-prints/${listingId}`;

  const result = await httpClient.patch({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return result;
};

const incrementShares = async ({ listingId }) => {
  const path = `/realestates/increment-shares/${listingId}`;
  return await httpClient.patch({
    path,
  });
};

/**
 * @param {string} adType Tipos aceitos: Venda, Lançamento ou Locação.
 * Caso contrário, informe null
 */

const getCities = async ({ adType }) => {
  let path;

  if (adType === null) {
    path = "/listings/search/inputs/cities";
  } else {
    path = `/listings/search/inputs/cities?type=${adType}`;
  }

  const result = await httpClient.get({ path });

  let types = [];
  if (result.data) {
    result.data.forEach((e, i) => {
      types.push({ label: e, id: i + 1 });
    });
  }
  const cities = [
    {
      label: "Cidades",
      id: 1,
      types: types,
    },
  ];
  return cities;
};

const getCitiesFromUser = async ({ token }) => {
  let path = "/realestates/search/cities";

  const result = await httpClient.get({ path, token });

  let types = [];

  if (result.data) {
    result.data.forEach((e, i) => {
      types.push({ label: e, id: i + 1 });
    });
  }

  const cities = [
    {
      label: "Cidades",
      id: 1,
      types: types,
    },
  ];

  return cities;
};

const getPropertyTypesFromUser = async ({ token }) => {
  let path = "/realestates/search/property-types";

  const response = await httpClient.get({ path, token });

  return response;
};

const getCitiesRealtor = async () => {
  const result = await httpClient.get({
    path: "/listings/search/inputs/cities",
  });

  let types = [];

  if (result.data) {
    result.data.forEach((e, i) => {
      types.push({ label: e, id: i + 1 });
    });
  }

  const cities = [
    {
      label: "Cidades",
      id: 1,
      types: types,
    },
  ];

  return cities;
};

const getDistricts = async ({ city }) => {
  const result = await httpClient.get({
    path: `/listings/search/inputs/neighborhoods/${city}`,
  });

  // let types = [{ label: "Todos os bairros", id: 0 }]
  let types = [];
  result.data.forEach((e, i) => {
    types.push({ label: e, id: i + 1 });
  });

  const districts = [
    {
      label: "Bairros",
      id: 1,
      types: types,
    },
  ];
  return districts;
};

const getDistrictsFromUser = async ({ city, token }) => {
  const result = await httpClient.get({
    path: `/realestates/search/${city}/neighborhoods`,
    token,
  });

  let types = [];

  result.data.forEach((e, i) => {
    types.push({ label: e, id: i + 1 });
  });

  const districts = [
    {
      label: "Bairros",
      id: 1,
      types: types,
    },
  ];

  return districts;
};

const searchRealtor = async ({ city, neighborhood, page = 1 }) => {
  let path = `/users/realtors/search?city=${city}&page=${page}`;

  if (neighborhood) {
    path = `/users/realtors/search?city=${city}&neighborhood=${neighborhood}&page=${page}`;
  }

  const result = await httpClient.get({ path });

  return result;
};

const getDistrictsRealtor = async ({ city }) => {
  const result = await httpClient.get({
    path: `/listings/search/inputs/neighborhoods/${city}`,
  });

  let types = [];

  result.data.forEach((e, i) => {
    types.push({ label: e, id: i + 1 });
  });

  const districts = [
    {
      label: "Bairros",
      id: 1,
      types: types,
    },
  ];

  return districts;
};

const getAds = async ({ userId, page = 1, transaction }) => {
  try {
    const path = `/users/${userId}/ads?transaction=${transaction}&page=${page}`;

    const { data } = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
    });

    const result = data.map((item) => {
      return new RealEstate({
        seo: item.ads.seo,
        listingId: item._id,
        ...item,
        ...item.ads.types,
        area: item.realEstate.area,
        bedrooms: item.realEstate.bedrooms,
        address: new Address({
          ...item.realEstate.address,
          geolocation: new Geolocation({
            ...item.realEstate.address.location,
            latitude: item.realEstate.address.location.coordinates[1],
            longitude: item.realEstate.address.location.coordinates[0],
          }),
        }),
        price: new Price({
          ...item.ads.prices,
          main: item.ads.prices.main,
        }),
        images: (item.ads.images.ad.slice(0, 3) || [])
          .map(
            (url) =>
              new Image({
                url: url.replace("{width}", "316"),
                size: "g",
                type: IMAGE_TYPES.PICTURE,
              })
          )
          .concat(
            (item.ads.images.floor_plan || []).map(
              (url) =>
                new Image({
                  url,
                  type: IMAGE_TYPES.FLOOR_PLAN,
                })
            )
          )
          .concat(
            (item.ads.images.tour360 || []).map(
              (url) =>
                new Image({
                  url,
                  type: IMAGE_TYPES.PANORAMA,
                })
            )
          ),
      });
    });

    return result;
  } catch (err) {
    console.log(err);
  }
};

export default {
  getNew,
  getAds,
  get,
  getById,
  informPrint,
  getCitiesRealtor,
  getDistrictsRealtor,
  searchRealtor,
  incrementShares,
  getByIdInDashboard,
  getBySlugInDashboard,
  getCities,
  getDistricts,
  getCitiesFromUser,
  getDistrictsFromUser,
  getPropertyTypesFromUser,
};
