import httpClient from "../../clients/http/http.client";
import Address from "../../models/address/address.model";
import Geolocation from "../../models/geolocation/geolocation.model";
import Image, { TYPES as IMAGE_TYPES } from "../../models/image/image.model";
import Feature, { GROUP_TYPES } from "../../models/feature/feature.model";
import Price from "../../models/price/price.model";
import objectUtil from "../../utils/object/object.util";
import RealEstate from "../../models/real-estate/real-estate.model";
import Publisher from "../../models/publisher/publisher.model";
import userService from "../user/user.service";

const BASE_PATH = "/api/v2";
const { REACT_APP_SEARCH_API_URL } = process.env;

const get = async ({
  type,
  bedrooms,
  bathrooms,
  suites,
  parkingSpaces,
  area,
  mainPrice,
  rect,
  transaction,
  use,
  typeOfGuarantee,
  features,
  ids,
  text,
  city,
  uf,
  neighborhood,
  page = 1,
  sizePagination = 10,
  token,
  realEstatesIds = null,
  orderBy="updatedAt",
  sort="asc"
}) => {
  const path = `${BASE_PATH}/listings`;

  const params = {};
  if (ids) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    params.ids = objectUtil.stringify(ids);
  } else {
    if (realEstatesIds) {
      params.ids = `${realEstatesIds}`;
    }

    if (type !== undefined) {
      if (!Array.isArray(type)) {
        type = [type];
      }
      params.type = objectUtil.stringify(type);
    }
    if (bedrooms !== undefined) {
      if (!Array.isArray(bedrooms)) {
        bedrooms = [bedrooms];
      }
      params.bedrooms =objectUtil.stringify(bedrooms);
    }
    if (bathrooms !== undefined) {
      if (!Array.isArray(bathrooms)) {
        bathrooms = [bathrooms];
      }
      params.bathrooms = objectUtil.stringify(bathrooms);
    }
    if (suites !== undefined) {
      if (!Array.isArray(suites)) {
        suites = [suites];
      }
      params.suites = objectUtil.stringify(suites);
    }
    if (parkingSpaces !== undefined) {
      if (!Array.isArray(parkingSpaces)) {
        parkingSpaces = [parkingSpaces];
      }
      params.parkingSpaces = objectUtil.stringify(parkingSpaces);
    }
    if (area !== undefined) {
      if (!Array.isArray(area)) {
        area = [area];
      }
      params.area = objectUtil.stringify(area);
    }
    if (mainPrice !== undefined) {
      if (!Array.isArray(mainPrice)) {
        mainPrice = [mainPrice];
      }
      params.mainPrice = objectUtil.stringify(mainPrice);
    }
    if (rect !== undefined && !realEstatesIds) {
      if (!Array.isArray(rect)) {
        rect = [rect];
      }
      params.rect = objectUtil.stringify(rect);
    }
    if (city) {
      params.city = city;
    }
    if (uf) {
      params.uf = uf;
    }
    if (neighborhood) {
      if (!Array.isArray(neighborhood)) {
        neighborhood = [neighborhood];
      }
      params.neighborhood = objectUtil.stringify(neighborhood);
    }
    if (transaction !== undefined) {
      params.transaction = objectUtil.stringify(transaction);
    }
    if (use !== undefined) {
      if (!Array.isArray(use)) {
        use = [use];
      }
      params.use = objectUtil.stringify(use);
    }
    if (typeOfGuarantee !== undefined) {
      if (!Array.isArray(typeOfGuarantee)) {
        typeOfGuarantee = [typeOfGuarantee];
      }
      params.typeOfGuarantee = objectUtil.stringify(typeOfGuarantee);
    }
    if (features !== undefined) {
      if (!Array.isArray(features)) {
        features = [features];
      }
      params.features = objectUtil.stringify(features);
    }
    if (text !== undefined) params.text = text;
  }

  params.page = objectUtil.stringify(page);
  params.size = objectUtil.stringify(sizePagination);
  params.orderBy = orderBy;
  params.sort = sort;

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    params,
    token: token,
  });

  const { items, pagination, seo } = response.data;

  const result = items.map((i) => {
    const mapped = new RealEstate({
      seo: i.seo,
      listingId: i._id,
      slug: i.slug,
      ...i.types,
      favorite: i.favorite,
      area: i.area,
      bedrooms: i.bedrooms,
      images: i.images.slice(0, 3).map((item) => {
        return {
          url: item,
          type: "PICTURE",
        };
      }),
      address: new Address({
        ...i.address,
        geolocation: new Geolocation({
          latitude: i.address.location.coordinates[1],
          longitude: i.address.location.coordinates[0],
        }),
      }),
      city: i.address.city,
      neighborhood: i.address.neighborhood,
      price: new Price({
        ...i.prices,
        main: i.prices.main,
      }),
    });

    return mapped;
  });

  return { realEstates: result, pagination, seo };
};

const getCountSearchListingFilterResults = async ({
  type,
  bedrooms,
  bathrooms,
  suites,
  parkingSpaces,
  area,
  mainPrice,
  rect,
  transaction,
  use,
  typeOfGuarantee,
  features,
  ids,
  city,
  uf,
  neighborhood,
}) => {
  const path = `${BASE_PATH}/listings/total`;

  const params = {};
  if (ids) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    params.ids = objectUtil.stringify(ids);
  } else {
    if (type !== undefined && type.length > 0) {
      if (!Array.isArray(type)) {
        type = [type];
      }
      params.type = objectUtil.stringify(type);
    }
    if (bedrooms !== undefined && bedrooms !== null) {
      if (!Array.isArray(bedrooms)) {
        bedrooms = [bedrooms];
      }
      params.bedrooms = objectUtil.stringify(bedrooms);
    }
    if (bathrooms !== undefined && bathrooms !== null) {
      if (!Array.isArray(bathrooms)) {
        bathrooms = [bathrooms];
      }
      params.bathrooms = objectUtil.stringify(bathrooms);
    }
    if (suites !== undefined && suites !== null) {
      if (!Array.isArray(suites)) {
        suites = [suites];
      }
      params.suites = objectUtil.stringify(suites);
    }
    if (parkingSpaces !== undefined && parkingSpaces !== null) {
      if (!Array.isArray(parkingSpaces)) {
        parkingSpaces = [parkingSpaces];
      }
      params.parkingSpaces = objectUtil.stringify(parkingSpaces);
    }
    if (area !== undefined) {
      if (!Array.isArray(area)) {
        area = [area];
      }
      params.area = objectUtil.stringify(area);
    }
    if (mainPrice !== undefined) {
      if (!Array.isArray(mainPrice)) {
        mainPrice = [mainPrice];
      }
      params.mainPrice = objectUtil.stringify(mainPrice);
    }
    if (!!rect) {
      if (!Array.isArray(rect)) {
        rect = [rect];
      }
      params.rect = objectUtil.stringify(rect);
    }
    if (city) {
      params.city = city;
    }
    if (uf) {
      params.uf = uf;
    }
    if (neighborhood) {
      params.neighborhood = neighborhood;
    }
    if (transaction !== undefined) {
      params.transaction = objectUtil.stringify(transaction);
    }
    if (use !== undefined && use.length > 0) {
      if (!Array.isArray(use)) {
        use = [use];
      }
      params.use = objectUtil.stringify(use);
    }
    if (typeOfGuarantee !== undefined) {
      if (!Array.isArray(typeOfGuarantee)) {
        typeOfGuarantee = [typeOfGuarantee];
      }
      params.typeOfGuarantee = objectUtil.stringify(typeOfGuarantee);
    }
    if (features !== undefined && features.length > 0) {
      if (!Array.isArray(features)) {
        features = [features];
      }
      params.features = objectUtil.stringify(features);
    }
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    params,
  });

  return response;
};

const getCoordinates = async ({
  type,
  bedrooms,
  bathrooms,
  suites,
  parkingSpaces,
  area,
  mainPrice,
  rect,
  transaction,
  use,
  typeOfGuarantee,
  features,
  ids,
  text,
  city,
  neighborhood,
  state,
  token,
}) => {
  const path = `${BASE_PATH}/listings/coordinates/get`;
  const params = {};

  if (ids) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    params.ids = objectUtil.stringify(ids);
  } else {
    if (type !== undefined) {
      if (!Array.isArray(type)) {
        type = [type];
      }
      params.type = objectUtil.stringify(type);
    }
    if (bedrooms !== undefined) {
      if (!Array.isArray(bedrooms)) {
        bedrooms = [bedrooms];
      }
      params.bedrooms = objectUtil.stringify(bedrooms);
    }
    if (bathrooms !== undefined) {
      if (!Array.isArray(bathrooms)) {
        bathrooms = [bathrooms];
      }
      params.bathrooms = objectUtil.stringify(bathrooms);
    }
    if (suites !== undefined) {
      if (!Array.isArray(suites)) {
        suites = [suites];
      }
      params.suites = objectUtil.stringify(suites);
    }
    if (parkingSpaces !== undefined) {
      if (!Array.isArray(parkingSpaces)) {
        parkingSpaces = [parkingSpaces];
      }
      params.parkingSpaces = objectUtil.stringify(parkingSpaces);
    }
    if (area !== undefined) {
      if (!Array.isArray(area)) {
        area = [area];
      }
      params.area = objectUtil.stringify(area);
    }
    if (mainPrice !== undefined) {
      if (!Array.isArray(mainPrice)) {
        mainPrice = [mainPrice];
      }
      params.mainPrice = objectUtil.stringify(mainPrice);
    }
    if (rect !== undefined) {
      if (!Array.isArray(rect)) {
        rect = [rect];
      }
      params.rect = objectUtil.stringify(rect);
    }
    if (city) {
      params.city = city;
    }
    if (state) {
      params.state = state;
    }
    if (neighborhood) {
      if (!Array.isArray(neighborhood)) {
        neighborhood = [neighborhood];
      }
      params.neighborhood = objectUtil.stringify(neighborhood);
    }
    if (transaction !== undefined) {
      params.transaction = objectUtil.stringify(transaction);
    }
    if (use !== undefined) {
      if (!Array.isArray(use)) {
        use = [use];
      }
      params.use = objectUtil.stringify(use);
    }
    if (typeOfGuarantee !== undefined) {
      if (!Array.isArray(typeOfGuarantee)) {
        typeOfGuarantee = [typeOfGuarantee];
      }
      params.typeOfGuarantee = objectUtil.stringify(typeOfGuarantee);
    }
    if (features !== undefined) {
      if (!Array.isArray(features)) {
        features = [features];
      }
      params.features = objectUtil.stringify(features);
    }
    if (text !== undefined) params.text = text;
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    params,
    token: token,
  });

  return { coordinates: response.data.items, longLat: response.data.rect };
};

const getRealEstatesByIds = async ({ ids, token }) => {
  const path = `${BASE_PATH}/listings?ids=${ids}`;

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    token,
  });

  return response;
};

const getById = async ({ listingId, token = null }) => {
  try {
    const path = `${BASE_PATH}/listings/${listingId}`;
    let response;

    if (token !== null) {
      response = await httpClient.get({
        url: `${REACT_APP_SEARCH_API_URL}`,
        path,
        token,
      });
    } else {
      response = await httpClient.get({
        url: `${REACT_APP_SEARCH_API_URL}`,
        path,
      });
    }

    const data = response.data;

    const USER_TYPES = {
      3: "CORRETOR(A)",
      4: "IMOBILIÁRIA",
      5: "INCORPORADORA",
    };

    let type = USER_TYPES[data.publisher.userType];

    if (data.features instanceof Array) {
      data.features = {
        property: data.features,
      };
    }
    const { ad: adImages, floor_plan, tour360 } = data.images;
    const { near, condo, property } = data.features;

    const result = new RealEstate({
      incorporation: data.incorporation ?? null,
      development: data.development ?? null,
      listingId: data._id,
      ...data,
      ...data.types,
      area: data.area,
      bathrooms: data.bathrooms,
      petAcceptance: !!condo.find((c) => c === "Aceita Pet"),
      bedrooms: data.bedrooms,
      parkingSpaces: data.parkingSpaces,
      highlight: data.types.publication === "Super Destaque",
      subtitle: data.subTitle,
      suites: data.suites,
      address: new Address({
        ...data.address,
        geolocation: new Geolocation({
          ...data.address.location,
          latitude: data.address.location.coordinates[1],
          longitude: data.address.location.coordinates[0],
        }),
      }),
      price: new Price({
        ...data.prices,
        main: data.prices.main,
      }),
      features: (near || [])
        .map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.SURROUNDINGS,
            })
        )
        .concat(
          (condo || []).map(
            (l) =>
              new Feature({
                label: l,
                group: GROUP_TYPES.CONDO,
              })
          )
        )
        .concat(
          (property || []).map(
            (l) =>
              new Feature({
                label: l,
                group: GROUP_TYPES.PROPERTY,
              })
          )
        ),
      images: (data.images.ad || [])
        .map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              size: "g",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        )
        .concat(
          (data.images && data.images.slides ? data.images.slides : []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.SLIDES,
              })
          )
        ),
      publisher: new Publisher({
        ...data.publisher,
        emails: [data.publisher.email],
        pictureUrl: data.publisher.profile.image,
        id: data.publisher._id,
        type,
      }),
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

const getBySlug = async ({ slug, token = null }) => {
  try {
    console.log("call getBySlug");
    if (slug.includes("-id-")) {
      const itemsFromSlugSplited = slug.split("-");
      slug = itemsFromSlugSplited[itemsFromSlugSplited.length - 1];
    }
    const path = `${BASE_PATH}/listings/slug/${slug}`;
    let response;

    if (token !== null) {
      response = await httpClient.get({
        url: `${REACT_APP_SEARCH_API_URL}`,
        path,
        token,
      });
    } else {
      response = await httpClient.get({
        url: `${REACT_APP_SEARCH_API_URL}`,
        path,
      });
    }

    const data = response.data;

    const USER_TYPES = {
      3: "CORRETOR(A)",
      4: "IMOBILIÁRIA",
      5: "INCORPORADORA",
    };

    let type = USER_TYPES[data.publisher.userType];

    if (data.features instanceof Array) {
      data.features = {
        property: data.features,
      };
    }
    const { ad: adImages, floor_plan, tour360 } = data.images;
    const { near, condo, property } = data.features;

    const result = new RealEstate({
      seo: data.seo,
      incorporation: data.incorporation ?? null,
      development: data.development ?? null,
      listingId: data._id,
      ...data,
      ...data.types,
      area: data.area,
      externalId: data.externalId,
      bathrooms: data.bathrooms,
      petAcceptance: !!condo.find((c) => c === "Aceita Pet"),
      bedrooms: data.bedrooms,
      parkingSpaces: data.parkingSpaces,
      highlight: data.types.publication === "Super Destaque",
      subtitle: data.subTitle,
      suites: data.suites,
      address: new Address({
        ...data.address,
        geolocation: new Geolocation({
          ...data.address.location,
          latitude: data.address.location.coordinates[1],
          longitude: data.address.location.coordinates[0],
        }),
      }),
      price: new Price({
        ...data.prices,
        main: data.prices.main,
      }),
      featuresOriginFormat: data.features,
      features: (near || [])
        .map(
          (l) =>
            new Feature({
              label: l,
              group: GROUP_TYPES.SURROUNDINGS,
            })
        )
        .concat(
          (condo || []).map(
            (l) =>
              new Feature({
                label: l,
                group: GROUP_TYPES.CONDO,
              })
          )
        )
        .concat(
          (property || []).map(
            (l) =>
              new Feature({
                label: l,
                group: GROUP_TYPES.PROPERTY,
              })
          )
        ),
      images: (data.images.ad || [])
        .map(
          (url) =>
            new Image({
              url: url.replace("{width}", "550"),
              size: "g",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        )
        .concat(
          (data.images && data.images.slides ? data.images.slides : []).map(
            (url) =>
              new Image({
                url,
                type: IMAGE_TYPES.SLIDES,
              })
          )
        ),
      publisher: new Publisher({
        ...data.publisher,
        emails: [data.publisher.email],
        pictureUrl: data.publisher.profile.image,
        id: data.publisher._id,
        type,
      }),
    });
    console.log("ID: ", result)
    return result;
  } catch (error) {
    throw new Error(error);
  }
};

const getRecommendedAds = async ({ id, similarityType, token = null }) => {
  const path = `${BASE_PATH}/listings/${id}/recommendations`;

  let response;

  if (token !== null) {
    response = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
      token,
    });
  } else {
    response = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
    });
  }

  const listingsInTheSameNeighborhood =
    response.data.listingsInTheSameNeighborhood.map((item) => {
      return new RealEstate({
        ...item,
        ...item.types,
        area: item.area,
        bedrooms: item.bedrooms,
        id: item._id,
        realEstateId: item._id,
        address: new Address({
          ...item.address,
          geolocation: new Geolocation({
            ...item.address.location,
            latitude: item.address.location.coordinates[1],
            longitude: item.address.location.coordinates[0],
          }),
        }),
        images: (item.images.slice(0, 3) || []).map(
          (url) =>
            new Image({
              url: url.replace("{width}", "316"),
              size: "p",
              type: IMAGE_TYPES.PICTURE,
            })
        ),
        price: new Price({
          ...item.prices,
          main: item.prices.main,
        }),
      });
    });

  const othersListings = response.data.othersListings.map((item) => {
    return new RealEstate({
      ...item,
      ...item.types,
      area: item.area,
      bedrooms: item.bedrooms,
      id: item._id,
      realEstateId: item._id,
      address: new Address({
        ...item.address,
        geolocation: new Geolocation({
          ...item.address.location,
          latitude: item.address.location.coordinates[1],
          longitude: item.address.location.coordinates[0],
        }),
      }),
      images: (item.images.slice(0, 3) || []).map(
        (url) =>
          new Image({
            url: url.replace("{width}", "316"),
            size: "p",
            type: IMAGE_TYPES.PICTURE,
          })
      ),
      price: new Price({
        ...item.prices,
        main: item.prices.main,
      }),
    });
  });

  return {
    listingsInTheSameNeighborhood,
    othersListings,
    status: response.status,
  };
};

const getRecentRecommendationsAdsByHome = async ({
  page = 1,
  pageSize = 10,
}) => {
  const skip = (page - 1) * pageSize;

  let path = `${BASE_PATH}/recommendations/listings/recents?size=20`;

  const userCoordinates = JSON.parse(
    localStorage.getItem("@Koort:UserCoordinates")
  );

  if (userCoordinates) {
    path = `${path}&lat=${userCoordinates[0]}&lng=${userCoordinates[1]}`;
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  const { listings } = response.data;

  const result = listings.map((item) => {
    const mapped = new RealEstate({
      listingId: item._id,
      ...item,
      ...item.types,
      area: item.area[0],
      bedrooms: item.bedrooms[0],
      id: item._id,
      realEstateId: item._id,
      address: new Address({
        ...item.address,
      }),
      images: (item.images || []).map(
        (url) =>
          new Image({
            url: url.replace("{width}", "1024"),
            size: "p",
            type: IMAGE_TYPES.PICTURE,
          })
      ),
      price: new Price({
        ...item.prices,
        main: item.prices.main,
      }),
    });

    return mapped;
  });

  let startSlice, endSlice;
  if (result.length > skip + pageSize) {
    startSlice = skip;
    endSlice = skip + pageSize;
  } else {
    startSlice = 0;
    endSlice = pageSize;
  }

  return result.slice(startSlice, endSlice);
};

const getRecommendationsAdsForUserByHome = async () => {
  const userStored = userService.retrieve();

  let path = `${BASE_PATH}/recommendations/listings/user?size=20`;

  const userCoordinates = JSON.parse(
    localStorage.getItem("@Koort:UserCoordinates")
  );

  if (userCoordinates) {
    path = `${path}&lat=${userCoordinates[0]}&lng=${userCoordinates[1]}`;
  }

  if (userStored && userStored.token) {
    path = `${path}&userId=${userStored._id}`;
  }

  let response;

  if (userService.retrieve() && userService.retrieve().token) {
    response = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
      token: userService.retrieve().token,
    });
  } else {
    response = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
    });
  }

  const { listings } = response.data;

  const result = listings.reverse().map((i) => {
    const mapped = new RealEstate({
      listingId: i._id,
      ...i.types,
      slug: i.slug,
      description: i.description,
      favorite: i.favorite,
      area: i.area[0],
      bedrooms: i.bedrooms[0],
      images: i.images.map((item) => {
        return {
          url: item,
          type: "PICTURE",
        };
      }),
      address: new Address({
        ...i.address,
      }),
      city: i.address.city,
      neighborhood: i.address.neighborhood,
      price: new Price({
        ...i.prices,
        main: i.prices.main,
      }),
    });

    return mapped;
  });

  return result;
};

const getProfessionalsByHome = async () => {
  const path = `${BASE_PATH}/users/professionals`;

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return response;
};

const getProfessionalsBySearch = async ({
  city,
  neighborhood,
  page = 1,
  userTypes = "3, 4, 5",
}) => {
  // userTypes=3, 4
  // 3: corretores
  // 4: imobiliárias

  let path = `${BASE_PATH}/users/professionals?userTypes=${userTypes}&page=${page}`;

  if (city) {
    path = `${path}&city=${city}`;
  }

  if (neighborhood) {
    path = `${path}&neighborhood=${neighborhood}`;
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return response;
};

const getCities = async ({ adType = null }) => {
  // type: Venda, Lançamento, Locação

  let path = `${BASE_PATH}/regions`;

  if (adType !== null) {
    path = `${path}?type=${adType}`;
  }

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return response;
};

const getDistricts = async ({ city, adType }) => {
  // type: Venda, Lançamento, Locação

  let path = `${BASE_PATH}/regions/from/${city}?type=${adType}`;

  try {
    const response = await httpClient.get({
      url: `${REACT_APP_SEARCH_API_URL}`,
      path,
    });

    return response;
  } catch (error) {
    return error
  }
};

const getFeatures = async (adType, city) => {
  const path = `${BASE_PATH}/features/from/listings?type=${adType}&city=${city}`;

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return response;
};

const getReleaseAdsByHome = async () => {
  const path = `${BASE_PATH}/listings/highlights/from/home`;

  const response = await httpClient.get({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
  });

  return response;
};

export default {
  get,
  getCoordinates,
  getRealEstatesByIds,
  getById,
  getBySlug,
  getRecommendedAds,
  getRecentRecommendationsAdsByHome,
  getRecommendationsAdsForUserByHome,
  getProfessionalsByHome,
  getProfessionalsBySearch,
  getCities,
  getDistricts,
  getFeatures,
  getReleaseAdsByHome,
  getCountSearchListingFilterResults,
};
