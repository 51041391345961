import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Slide } from "react-slideshow-image";
import { useAuth0 } from '@auth0/auth0-react';

import userFavoriteService from '../../services/user/favorite/user.favorite.service';

import { TYPES as IMAGE_TYPES } from '../../models/image/image.model';
import pathConstants from '../../constants/paths';
import currencyUtil from '../../utils/currency/currency.util';

import {
  Wrapper,
  FavoriteButton,
  HeartOutlineIcon,
  HeartFullIcon,
  InfoWrapper,
  Label,
  City,
  Neighborhood,
  InlineWrapper,
  M2Icon,
  Area,
  BedroomsIcon,
  Bedrooms,
  Price,
  WrapperLoading,
  CityWrapper,
  ImageOverlay,
  CityInfo,
  BadgesContainer,
  ReleaseBadge,
  PreReleaseBadge,
} from './styles';

function AdCardInteractive(props) {
  const [data, setData] = useState(null);
  const [isFavorite, setIsFavorite] = useState(props.isFavorite);

  const { loginWithRedirect } = useAuth0();

  async function handleFavoriteClick(event) {
    event.stopPropagation();

    const { listingId, realEstateId, user, slug } = props;

    if (!user) {
      const pathRedirect = `${pathConstants.AD_DETAIL}/${slug}?action=autoFavorite`;
      localStorage.setItem('@Koort:PathRedirect', pathRedirect);

      loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
    } else {
      setIsFavorite(state => !state);

      let result;
      try {
        result = await userFavoriteService.patch({
          listingId: listingId || realEstateId,
          token: user.token,
        });
        userFavoriteService.save({
          listingIds: result,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setData(true)
    }, Math.floor(Math.random() * 800) + 200);

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  const {
    className,
    images,
    area,
    bedrooms,
    price,
    city,
    neighborhood,
    property,
    transaction,
    onMouseEnter,
    onMouseOut,
    listingId,
    realEstateId,
    listing,
    slug,
    bgColor = 'transparent',
    seo,
    // transactionTypeInMapPage = null,
  } = props;

  const images_slide = images.filter(({ type }) => type === IMAGE_TYPES.PICTURE).map(image => image.url.replace('{width}', '316'))

  let priceValue = {
    ...price,
  }

  switch (transaction) {
    case 'Locação':
    case 'Locação|Venda':
      priceValue = {
        ...price,
        main: price.rental,
      }
      break;

    case 'Venda':
    case 'Venda|Locação':
      priceValue = {
        ...price,
        main: price.main,
      }
      break;

    default:
      break;
  }

  // if (transactionTypeInMapPage !== null) {
  //   if (Number(transactionTypeInMapPage) === 2) {
  //     priceValue = {
  //       ...price,
  //       main: price.rental,
  //     }
  //   }
  // }

  if (data) {
    return (
      <Wrapper
        className={className}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseEnter}
        bgColor={bgColor}
      >
        {!!images_slide.length && (
          <Slide {...properties}>
            {images_slide.map((each, index) => (
              <a
                key={index}
                style={style}
                href={`${pathConstants.AD_DETAIL}/${slug}`}
                target='_blank'
                rel="noreferrer noopener"
              >
                <img
                  src={each}
                  style={styleImg}
                  alt={seo && seo.title ? `${seo.title} - Foto ${index + 1}` : `Foto ${index + 1}`}
                />
                <ImageOverlay />
              </a>
            ))}
          </Slide>
        )}

        {!images_slide.length && (
          <a
            style={style}
            href={`${pathConstants.AD_DETAIL}/${slug}`}
            target='_blank'
            rel="noreferrer noopener"
          >
            <img
              src={`${process.env.PUBLIC_URL}/imgs/website-preview-overlay.webp`}
              style={styleImgOverlay}
              alt="Imagem padrão do Koort Imóveis"
            />
          </a>
        )}
        <InfoWrapper>
          <CityWrapper>
            <CityInfo>
              <Neighborhood as="h3" title={neighborhood}>{neighborhood}</Neighborhood>
              <City>{city}</City>
            </CityInfo>

            <FavoriteButton onClick={handleFavoriteClick}>
              {!isFavorite && <HeartOutlineIcon />}
              {isFavorite && <HeartFullIcon />}
            </FavoriteButton>
          </CityWrapper>
          <Label className="transaction-label">[{transaction}] [ {property} ]</Label>
          <InlineWrapper>
            <M2Icon />
            <Area>
              {area.length === 1 && (
                area[0] !== 0 ?
                `${area} m²` :
                "Consultar"
              )}

              {area.length === 2 && area[0] !== area[1] &&
                `${area[0]} - ${area[1]} m²`
              }

              {area.length === 2 && area[0] === area[1] &&
                `${area[0]} m²`
              }
            </Area>

            <BedroomsIcon />
            <Bedrooms>
              {bedrooms.length === 1 && (
                bedrooms[0] !== 0 ?
                `${bedrooms}` :
                "Consultar"
              )}

              {bedrooms.length === 2 && bedrooms[0] !== bedrooms[1] &&
                `${bedrooms[0]} - ${bedrooms[1]}`
              }

              {bedrooms.length === 2 && bedrooms[0] === bedrooms[1] &&
                ( 
                  bedrooms[0] !== 0 ? 
                  `${bedrooms[0]}` : "Consultar"
                )
              }
            </Bedrooms>
          </InlineWrapper>
          <Price as="strong">
            {priceValue.main === 0 || priceValue.main[0] === 0 ? 'sob consulta' : currencyUtil.format({
              value: priceValue.main,
            })}
          </Price>
        </InfoWrapper>

        <BadgesContainer>
          {listing === 'Lançamento' && <ReleaseBadge>Lançamento</ReleaseBadge>}
          {listing === 'Pré-lançamento' && <PreReleaseBadge>Pré-lançamento</PreReleaseBadge>}
        </BadgesContainer>
      </Wrapper>
    );
  }

  return (
    <WrapperLoading />
  )
}

AdCardInteractive.propTypes = {};

AdCardInteractive.defaultProps = {};

const style = {
  textAlign: 'center',
  height: '160px',
  width: '100px',
};
const styleImg = {
  height: '160px',
  width: '100%',
};
const styleImgOverlay = {
  height: '160px',
  width: '100%',
  objectFit: 'cover'
};

const properties = {
  duration: 3000,
  transitionDuration: window.innerWidth < Number(720) ? 200 : 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  indicators: true,
  canSwipe: false,
  indicators: false,
  // prevArrow: <button type="button" aria-label="previous" className={"bt-new-slide"}><svg viewBox="0 0 24 24" height="60" width="60" aria-hidden="true" focusable="false" fill="currentColor" color="#e6e6e6"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path></svg></button>,
  prevArrow: <button type="button" aria-label="previous" className={"bt-new-slide"}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="white" fill-opacity="0.9" />
      <path d="M18 20.12L11.8192 14L18 7.88L16.0972 6L8 14L16.0972 22L18 20.12Z" fill="#7B7B7B" />
    </svg>
  </button>,
  nextArrow: <button type="button" aria-label="next" className={"bt-new-slide-next"}>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="white" fill-opacity="0.9" />
      <path d="M10 20.12L16.1808 14L10 7.88L11.9028 6L20 14L11.9028 22L10 20.12Z" fill="#7B7B7B" />
    </svg>
  </button>
};

export default withRouter(AdCardInteractive);
